import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonh4,Commonpara,Commonbtn} from '../Common/common.style';
import {Row} from '@bootstrap-styled/v4';

export const ImageTextSection = styled.section`
    padding:50px 0px 100px;

    @media ${device.tablet} {
        padding:40px 10px 80px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const TextLayout = styled.div`
    @media ${device.laptop} {
        margin-bottom:30px;
    }
`;

export const Heading = styled(Commonh4)`
    text-align:left;
    text-transform:uppercase;
`;

export const Description1 = styled(Commonpara)`

`;

export const List = styled.ul`
    list-style:none;
    margin:0;
`;

export const ListItem = styled.li`
    display:flex;
    margin-bottom: 10px;
`;

export const LeftIcon = styled.img`
    height:30px;
    width:30px;
    flex-shrink:0;
    margin-top:5px;
`;

export const ListText = styled(Commonpara)`
    line-height:1;
    margin-bottom:0;
    margin-left:20px;
    line-height:25px;
`;

export const ContactUsBtn = styled(Commonbtn)`
    margin-top:15px;
`;

export const ImageHolder = styled.div`
    padding-left:50px;

    @media ${device.laptop} {
        padding-left:0px;
    }
`;



import React from 'react';
import {CoursesSection,HeadingLayout,CoursesHeading,CoursesCard,TextLayout,
    CourseName,CourseDesc,DetailsList,DetailsListItem,RightArrowIcon,DetailsText,ApplyNowBtn
} from './courses.style';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const Courses = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    Courses{
                        SectionHeading

                        CourseImg1{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        CourseName1
                        CourseDesc1
                        DetailsText1
                        DetailsText2

                        CourseImg2{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        CourseName2
                        CourseDesc2
                        DetailsText3
                        DetailsText4

                        CourseImg3{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        CourseName3
                        CourseDesc3
                        DetailsText5
                        DetailsText6
                        ApplyNowBtn
                    }
                }
            }
    `);
        return (
        <CoursesSection id="coursesContainer">
            <Container>
                <Row>
                    <Col md={12}>
                        <HeadingLayout>
                            <CoursesHeading>
                            {JSONData.instituteJson.Courses.SectionHeading}
                            </CoursesHeading>
                        </HeadingLayout>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <CoursesCard>
                            <GatsImg 
                                fluid={JSONData.instituteJson.Courses.CourseImg1.childImageSharp.fluid} 
                                alt=""
                                className="coursesImg"
                            />
                            <TextLayout>
                                <CourseName>
                                {JSONData.instituteJson.Courses.CourseName1}
                                </CourseName>
                                <CourseDesc>
                                {JSONData.instituteJson.Courses.CourseDesc1}
                                </CourseDesc>
                                <DetailsList>
                                    <DetailsListItem>
                                        <RightArrowIcon />
                                        <DetailsText>
                                        {JSONData.instituteJson.Courses.DetailsText1}
                                        </DetailsText>
                                    </DetailsListItem>
                                    <DetailsListItem>
                                        <RightArrowIcon />
                                        <DetailsText>
                                        {JSONData.instituteJson.Courses.DetailsText2}
                                        </DetailsText>
                                    </DetailsListItem>
                                </DetailsList>
                                <ApplyNowBtn>
                                {JSONData.instituteJson.Courses.ApplyNowBtn} 
                                </ApplyNowBtn>
                            </TextLayout>
                        </CoursesCard>
                    </Col>
                    <Col lg={4}>
                        <CoursesCard>
                            <GatsImg 
                                fluid={JSONData.instituteJson.Courses.CourseImg2.childImageSharp.fluid} 
                                alt=""
                                className="coursesImg"
                            />
                            <TextLayout>
                                <CourseName>
                                {JSONData.instituteJson.Courses.CourseName2}
                                </CourseName>
                                <CourseDesc>
                                {JSONData.instituteJson.Courses.CourseDesc2}
                                </CourseDesc>
                                <DetailsList>
                                    <DetailsListItem>
                                        <RightArrowIcon />
                                        <DetailsText>
                                        {JSONData.instituteJson.Courses.DetailsText3}
                                        </DetailsText>
                                    </DetailsListItem>
                                    <DetailsListItem>
                                        <RightArrowIcon />
                                        <DetailsText>
                                        {JSONData.instituteJson.Courses.DetailsText4}
                                        </DetailsText>
                                    </DetailsListItem>
                                </DetailsList>
                                <ApplyNowBtn>
                                {JSONData.instituteJson.Courses.ApplyNowBtn} 
                                </ApplyNowBtn>
                            </TextLayout>
                        </CoursesCard>
                    </Col>
                    <Col lg={4}>
                        <CoursesCard>
                            <GatsImg 
                                fluid={JSONData.instituteJson.Courses.CourseImg3.childImageSharp.fluid} 
                                alt=""
                                className="coursesImg"
                            />
                            <TextLayout>
                                <CourseName>
                                {JSONData.instituteJson.Courses.CourseName3}
                                </CourseName>
                                <CourseDesc>
                                {JSONData.instituteJson.Courses.CourseDesc3}
                                </CourseDesc>
                                <DetailsList>
                                    <DetailsListItem>
                                        <RightArrowIcon />
                                        <DetailsText>
                                        {JSONData.instituteJson.Courses.DetailsText5}
                                        </DetailsText>
                                    </DetailsListItem>
                                    <DetailsListItem>
                                        <RightArrowIcon />
                                        <DetailsText>
                                        {JSONData.instituteJson.Courses.DetailsText6}
                                        </DetailsText>
                                    </DetailsListItem>
                                </DetailsList>
                                <ApplyNowBtn>
                                {JSONData.instituteJson.Courses.ApplyNowBtn} 
                                </ApplyNowBtn>
                            </TextLayout>
                        </CoursesCard>
                    </Col>
                </Row>
            </Container>
        </CoursesSection>
    );
}

export default Courses;
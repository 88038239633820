import React from 'react';
import {FeaturesSection,FeaturesSingle,FeaturesImg,Heading,FeaturesDesc
} from './features.style';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';

const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    Features{
                        FeaturesImg1
                        FeaturesHeading1
                        FeaturesDesc1
                        FeaturesImg2
                        FeaturesHeading2
                        FeaturesDesc2
                        FeaturesImg3
                        FeaturesHeading3
                        FeaturesDesc3
                        FeaturesImg4
                        FeaturesHeading4
                        FeaturesDesc4
                    }
                }
            }
    `);
        return (
        <FeaturesSection id="featuresContainer">
            <Container>
                <Row>
                    <Col lg={3} md={6}>
                        <FeaturesSingle>
                            <FeaturesImg 
                                src={JSONData.instituteJson.Features.FeaturesImg1} 
                                alt=""
                            />
                            <Heading>
                            {JSONData.instituteJson.Features.FeaturesHeading1} 
                            </Heading>
                            <FeaturesDesc>
                            {JSONData.instituteJson.Features.FeaturesDesc1}
                            </FeaturesDesc>
                        </FeaturesSingle>
                    </Col>
                    <Col lg={3} md={6}>
                        <FeaturesSingle>
                            <FeaturesImg 
                                src={JSONData.instituteJson.Features.FeaturesImg2} 
                                alt=""
                            />
                            <Heading>
                            {JSONData.instituteJson.Features.FeaturesHeading2} 
                            </Heading>
                            <FeaturesDesc>
                            {JSONData.instituteJson.Features.FeaturesDesc2}
                            </FeaturesDesc>
                        </FeaturesSingle>
                    </Col>
                    <Col lg={3} md={6}>
                        <FeaturesSingle>
                            <FeaturesImg 
                                src={JSONData.instituteJson.Features.FeaturesImg3} 
                                alt=""
                            />
                            <Heading>
                            {JSONData.instituteJson.Features.FeaturesHeading3} 
                            </Heading>
                            <FeaturesDesc>
                            {JSONData.instituteJson.Features.FeaturesDesc3}
                            </FeaturesDesc>
                        </FeaturesSingle>
                    </Col>
                    <Col lg={3} md={6}>
                        <FeaturesSingle>
                            <FeaturesImg 
                                src={JSONData.instituteJson.Features.FeaturesImg4} 
                                alt=""
                            />
                            <Heading>
                            {JSONData.instituteJson.Features.FeaturesHeading4} 
                            </Heading>
                            <FeaturesDesc>
                            {JSONData.instituteJson.Features.FeaturesDesc4}
                            </FeaturesDesc>
                        </FeaturesSingle>
                    </Col>
                </Row>
            </Container>
        </FeaturesSection>
    );
}

export default Features;
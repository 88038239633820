import React,{Component}from 'react';
import {TestimonialWrapper,HeadingLayout,TestimonialHeading,
    SliderOuterWrapper,LeftIcon,ImgButtonLeft,SliderWrapper,TestimonialCard,ImageLayout,
    TextLayout,StyledIconOpen,TestimonialText,QuoteHolder,StyleIconClose,Author,Designation,
    ImgButtonRight,RightIcon
} from './testimonials.style';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            className: "center-portfolio",
            centerMode: true,
            infinite: true,
            arrows:true,
            centerPadding:"0px",
            slidesToShow: 1,
            speed: 1000
        };

        return (
            <TestimonialWrapper id="testimonialsContainer">
                <Container>
                    <Row>
                        <Col md={12}>
                            <HeadingLayout>
                                <TestimonialHeading>
                                {this.props.instituteJson.Testimonials.TestimonialHeading} 
                                </TestimonialHeading>
                            </HeadingLayout>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                        <SliderOuterWrapper>
                            <ImgButtonLeft onClick={this.previous} aria-label="Prev Button">
                                <LeftIcon />
                            </ImgButtonLeft>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.instituteJson.Testimonials.TestimonialCards.map((item,idx) => {
                                return <SliderWrapper>
                                    <TestimonialCard>
                                        <ImageLayout>
                                            <GatsImg 
                                                fluid={item.Img.childImageSharp.fluid} 
                                                alt=""
                                                className="authorImg"
                                                imgStyle={{
                                                    objectPosition:"top"
                                                }}
                                            />
                                        </ImageLayout>
                                        <TextLayout>
                                            <StyledIconOpen />
                                            <TestimonialText>
                                            {item.TestimonialText} 
                                            </TestimonialText>
                                            <QuoteHolder>
                                            <StyleIconClose />
                                            </QuoteHolder>
                                            <Author>
                                            {item.Author} 
                                            </Author>
                                            <Designation>
                                            {item.Designation} 
                                            </Designation>
                                        </TextLayout>
                                    </TestimonialCard>
                                    </SliderWrapper>
                                })
                            }
                            </Slider>
                            <ImgButtonRight onClick={this.next} aria-label="Next Button">
                                <RightIcon />
                            </ImgButtonRight>
                            </SliderOuterWrapper>
                        </Col>
                    </Row>
                </Container>
            </TestimonialWrapper>
        );
    }
}
export default () => (
    <StaticQuery
        query={graphql`
            query {
                    instituteJson{
                        Testimonials{
                        TestimonialHeading
                        TestimonialCards{
                            Img{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            TestimonialText
                            Author
                            Designation
                        }
                        PrevImg
                        NextImg
                    }
                }
            }
            
        `}
        render={(data) => (
            <Testimonials instituteJson={data.instituteJson}/>
        )}
    />
  )
import React from 'react';
import {ImageTextSection,CustomRow,TextLayout,Heading,Description1,Description2,ContactUsBtn,ImageHolder
} from './imagetext1.style';
import {Container,Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const ImageText1 = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    ImageText1{
                       Heading
                       Description1
                       Description2
                       ContactUsBtn
                       Img{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                       }
                    }
                }
            }
    `);
        return (
        <ImageTextSection id="imagetext1Container">
            <Container>
                <CustomRow>
                    <Col md={12} lg={6}>
                        <TextLayout>
                            <Heading>
                            {JSONData.instituteJson.ImageText1.Heading} 
                            </Heading>
                            <Description1>
                            {JSONData.instituteJson.ImageText1.Description1} 
                            </Description1>
                            <Description2>
                            {JSONData.instituteJson.ImageText1.Description2} 
                            </Description2>
                            <ContactUsBtn>
                            {JSONData.instituteJson.ImageText1.ContactUsBtn} 
                            </ContactUsBtn>
                        </TextLayout>
                    </Col>
                    <Col md={12} lg={6}>
                        <ImageHolder>
                            <GatsImg 
                                fluid={JSONData.instituteJson.ImageText1.Img.childImageSharp.fluid} 
                                alt=""
                            />
                        </ImageHolder>
                    </Col>
                </CustomRow>
            </Container>
        </ImageTextSection>
    );
}

export default ImageText1;
import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonh5,Commonpara,SectionHeading,Commonbtn} from '../Common/common.style';
import {RightArrow} from 'styled-icons/boxicons-regular/RightArrow';

export const CoursesSection = styled.section`
    padding:100px 0px 70px;
    background:#fafafa;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const CoursesHeading = styled(SectionHeading)`

`;

export const CoursesCard = styled.div`
    background:#fff;
    box-shadow: 0px 0px 30px 0px #ddd;
    margin-bottom:30px;
    .coursesImg{
        height:200px;
    }
`;

export const TextLayout = styled.div`
    padding:20px;
`;

export const CourseName = styled(Commonh5)`
    text-align:left;
`;

export const CourseDesc = styled(Commonpara)`

`;

export const DetailsList = styled.ul`
    list-style:none;
    margin:0;
`;

export const DetailsListItem = styled.li`
    display:flex;
    align-items:center;
    margin-bottom: 10px;
`;
export const RightArrowIcon = styled(RightArrow)`
    color:#ef5350;
    height:20px;
    width:20px;
`;
export const DetailsText = styled(Commonpara)`
    line-height:1;
    margin-bottom:0;
    margin-left:5px;
`;

export const ApplyNowBtn = styled(Commonbtn)`
    margin-top:10px;

    @media ${device.laptop} {
        padding: 8px 30px;
    }
`;



import React from 'react';
import {AboutSection,CustomRow,ImgHolder,AboutLayout,TextLayout,AboutHeading,Description
} from './about.style';
import {Col,Container} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const AboutPage = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    About{
                        AboutImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        AboutHeading
                        Description1
                        Description2
                    }
                }
            }
    `);
        return (
        <AboutSection id="aboutContainer">
            <Container>
                <CustomRow>
                    <Col md="5">
                    <ImgHolder>
                        <GatsImg 
                            fluid={JSONData.instituteJson.About.AboutImg.childImageSharp.fluid} 
                            alt=""
                            className="aboutImg"
                        />
                        </ImgHolder>
                    </Col>
                    <Col md="7">
                        <AboutLayout>
                            <TextLayout>
                                <AboutHeading>
                                {JSONData.instituteJson.About.AboutHeading}
                                </AboutHeading>
                                <Description>
                                {JSONData.instituteJson.About.Description1}
                                </Description>
                                <Description>
                                {JSONData.instituteJson.About.Description2}
                                </Description>
                            </TextLayout>
                        </AboutLayout>
                    </Col>
                </CustomRow>
            </Container>
        </AboutSection>
    );
}

export default AboutPage;
import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonpara,Commonh3} from '../Common/common.style';
import BackgroundImg from '../../../assets/institute-images/about-section-banner.jpg';
import {Row} from '@bootstrap-styled/v4';

export const AboutSection = styled.section`
    background-image: url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    padding:100px 0px 80px;

    @media ${device.laptop} {
        background-position-x: 35%;
    }
    @media ${device.tablet} {
        padding:80px 10px 60px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const ImgHolder = styled.div`
    padding:20px;
`;

export const AboutLayout = styled.div`
`;

export const TextLayout = styled.div`
`;

export const AboutHeading = styled(Commonh3)`
    color:#fff;
`; 

export const Description = styled(Commonpara)`
    color:#fff;
`;

import React from 'react';
import {BannerSection,BannerContents,BannerContentsLeft,BannerHeading,
    BannerDescription,ButtonLayout,CallNowBtn,WatchVideoAnchor,WatchVideoLayout,
    PlayIcon,PlayText
} from './banner.style';
import {Container} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    Banner{
                        BannerHeading
                        BannerDescription
                        WatchVideoAnchor
                        BtnText
                    }
                }
            }
    `);
        return (
        <BannerSection id="homeContainer">
            <Container>
                <BannerContents>
                    <BannerContentsLeft>
                        <BannerHeading>
                        {JSONData.instituteJson.Banner.BannerHeading}
                        </BannerHeading>
                        <BannerDescription>
                        {JSONData.instituteJson.Banner.BannerDescription}
                        </BannerDescription>
                        <ButtonLayout>
                            <CallNowBtn>{JSONData.instituteJson.Banner.BtnText}</CallNowBtn>
                            <WatchVideoAnchor>
                                <WatchVideoLayout>
                                    <PlayIcon />
                                    <PlayText>
                                        {JSONData.instituteJson.Banner.WatchVideoAnchor}
                                    </PlayText>
                                </WatchVideoLayout>
                            </WatchVideoAnchor>
                        </ButtonLayout>
                    </BannerContentsLeft>
                </BannerContents>
            </Container>
        </BannerSection>
    );
}

export default Banner;
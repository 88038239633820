import React from 'react';
import {ImageTextSection,CustomRow,TextLayout,Heading,Description1,
    List,ListItem,RightArrowIcon,ListText,ContactUsBtn,ImageHolder
} from './imagetext2.style';
import {Container,Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const ImageText2 = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    ImageText2{
                       Heading
                       Description1
                       ListText1
                       ListText2
                       ListText3
                       ContactUsBtn
                       Img{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                       }
                    }
                }
            }
    `);
        return (
        <ImageTextSection id="imagetext2Container">
            <Container>
                <CustomRow>
                    <Col md={12} lg={6}>
                        <ImageHolder>
                            <GatsImg 
                                fluid={JSONData.instituteJson.ImageText2.Img.childImageSharp.fluid} 
                                alt=""
                            />
                        </ImageHolder>
                    </Col>
                    <Col md={12} lg={6}>
                        <TextLayout>
                            <Heading>
                            {JSONData.instituteJson.ImageText2.Heading} 
                            </Heading>
                            <Description1>
                            {JSONData.instituteJson.ImageText2.Description1} 
                            </Description1>
                            <List>
                                <ListItem>
                                    <RightArrowIcon />
                                    <ListText>{JSONData.instituteJson.ImageText2.ListText1}</ListText>
                                </ListItem>
                                <ListItem>
                                    <RightArrowIcon />
                                    <ListText>{JSONData.instituteJson.ImageText2.ListText2}</ListText>
                                </ListItem>
                                <ListItem>
                                    <RightArrowIcon />
                                    <ListText>{JSONData.instituteJson.ImageText2.ListText3}</ListText>
                                </ListItem>
                            </List>
                        
                            <ContactUsBtn>
                            {JSONData.instituteJson.ImageText2.ContactUsBtn} 
                            </ContactUsBtn>
                        </TextLayout>
                    </Col>
                </CustomRow>
            </Container>
        </ImageTextSection>
    );
}

export default ImageText2;
import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonh4,Commonpara,Commonbtn} from '../Common/common.style';
import {Row} from '@bootstrap-styled/v4';

export const ImageTextSection = styled.section`
    padding:100px 0px 50px;

    @media ${device.tablet} {
        padding:80px 10px 40px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const TextLayout = styled.div`
    @media ${device.laptop} {
        margin-bottom:30px;
    }
`;

export const Heading = styled(Commonh4)`
    text-align:left;
    text-transform:uppercase;
`;

export const Description1 = styled(Commonpara)`

`;

export const Description2 = styled(Commonpara)`

`;

export const ContactUsBtn = styled(Commonbtn)`

`;

export const ImageHolder = styled.div`
    padding-left:50px;

    @media ${device.laptop} {
        padding-left:0px;
    }
`;


